import { Icon } from '@/shared/components/Icon';
import {
  adminsIcon,
  billingIcon,
  dealsIcon,
  pricingIcon,
  projectsIcon,
  settingsIcon,
  statsIcon,
  usersIcon,
  vendorsIcon,
} from '@/assets/images/icons';
import { PAGES, UserRole, USER_TYPE } from '@/shared/consts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuProps } from 'antd';
import { PinkGradientLink } from '@/shared/components/Link/PinkGradientLink';
import { CaretDown } from '@/shared/components/Caret/CaretDown';
import { U1Link } from '@/shared/components/Link/U1Link';
import { PinkGradientRouterLink } from '@/shared/components/Link/PinkGradientRouterLink';
import { MenuItemProps, MenuItemType } from './MenuItems.consts';
import { isMobile } from 'react-device-detect';
import { useUserStore } from '@/stores/UserStore';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { SUB_STATUS } from '@/stores/Stores.consts';
import { buildLangPath } from '@/shared/utils';
import { checkPermission } from '@/shared/utils/auth';
import { AdminPermission } from '@/Views/AfterLogin/Main/Admin/Admin.consts';

export const useMenuItems = () => {
  const subscription = useSubscriptionStore();
  const userPermissions = useUserStore((state) => state.permissions);
  const userType = useUserStore((state) => state.type);
  const userRole = useUserStore((state) => state.role);
  const { t } = useTranslation();

  const getItem = (
    label: React.ReactNode,
    key: React.Key,
    href?: string,
    icon?: React.ReactNode,
    children?: MenuProps[],
    type?: 'group'
  ): MenuItemType => {
    return {
      key,
      icon,
      href,
      children,
      label,
      type,
    } as MenuItemType;
  };

  const getFinalItems = useCallback((menuItemProps: MenuItemProps[]): MenuItemType[] => {
    return menuItemProps.map((menuItem) =>
      getItem(menuItem.text, menuItem.key, menuItem.href, menuItem.icon)
    );
  }, []);

  const adminMenuItems = useMemo(
    (): MenuItemProps[] => [
      {
        key: 'docs',
        text: t('side_menu.docs'),
        permission: AdminPermission.READ_FRAMEWORK,
        href: PAGES.AFTER_LOGIN.ADMIN_DOCS,
        icon: <Icon $src={projectsIcon} />,
        order: 1,
      },
      {
        key: 'admins',
        text: t('side_menu.admins'),
        permission: AdminPermission.READ_ADMIN,
        href: PAGES.AFTER_LOGIN.ADMINS,
        icon: <Icon $src={adminsIcon} />,
        order: 2,
      },
      {
        key: 'vendors',
        text: t('side_menu.vendors'),
        permission: AdminPermission.READ_VENDOR,
        href: PAGES.AFTER_LOGIN.VENDORS,
        icon: <Icon $src={vendorsIcon} />,
        order: 3,
      },

      {
        key: 'leads',
        text: t('side_menu.bdr'),
        permission: AdminPermission.READ_LEAD,
        href: PAGES.AFTER_LOGIN.ADMIN_BDR,
        icon: <Icon $src={billingIcon} />,
        order: 4,
      },
      {
        key: 'package-versions',
        text: t('side_menu.versions'),
        permission: AdminPermission.READ_PACKAGE_VERSION,
        href: PAGES.AFTER_LOGIN.ADMIN_VERSIONS,
        icon: <Icon $src={projectsIcon} />,
        order: 5,
      },
    ],
    [t]
  );

  const vendorMenuItems = useMemo(
    (): MenuItemProps[] => [
      {
        key: 'deals',
        text: t('side_menu.deals'),
        href: PAGES.AFTER_LOGIN.DEALS,
        icon: <Icon $src={dealsIcon} />,
      },
      {
        key: 'stats',
        text: t('side_menu.stats'),
        href: PAGES.AFTER_LOGIN.STATS,
        icon: <Icon $src={statsIcon} />,
      },
      {
        key: 'settings',
        text: t('side_menu.settings'),
        href: PAGES.AFTER_LOGIN.SETTINGS,
        icon: <Icon $src={settingsIcon} />,
      },
    ],
    [t]
  );

  const clientMenuItems = useMemo((): MenuItemProps[] => {
    return [
      {
        key: 'projects',
        text: t('side_menu.projects'),
        href: PAGES.AFTER_LOGIN.PROJECTS,
        icon: <Icon $src={projectsIcon} />,
      },
      {
        key: 'users',
        text: t('side_menu.users'),
        href: PAGES.AFTER_LOGIN.USERS,
        icon: <Icon $src={usersIcon} />,
      },
      {
        key: 'billing',
        text: t('side_menu.billing'),
        href: PAGES.AFTER_LOGIN.BILLING,
        icon: <Icon $src={billingIcon} />,
      },
      {
        key: 'pricing',
        text: t('side_menu.pricing'),
        href: PAGES.AFTER_LOGIN.PRICING,
        icon: <Icon $src={pricingIcon} data-testid="pricingIcon" />,
      },
    ];
  }, [t]);

  const clientMenu = useMemo(
    (): Record<string, MenuItemProps[]> => ({
      basic: [clientMenuItems[3]],
      user: [clientMenuItems[0]],
      admin: [clientMenuItems[0], clientMenuItems[1], clientMenuItems[2]],
      freemium: [clientMenuItems[0], clientMenuItems[1], clientMenuItems[2], clientMenuItems[3]],
      disabled: [clientMenuItems[0], clientMenuItems[2], clientMenuItems[3]],
    }),
    [clientMenuItems]
  );

  const adminMenu = useMemo(
    (): MenuItemProps[] =>
      adminMenuItems
        .filter((item) => checkPermission(userType, userPermissions, item.permission))
        .sort((a, b) => (a.order || 1) - (b.order || 1)),
    [adminMenuItems, userType, userPermissions]
  );

  const vendorMenu = useMemo(
    (): MenuItemProps[] => [vendorMenuItems[0], vendorMenuItems[1], vendorMenuItems[2]],
    [vendorMenuItems]
  );

  const getClientMenuItems = useCallback(
    (type: string): MenuItemType[] => {
      if (isMobile) return clientMenu[type];
      return getFinalItems(clientMenu[type]);
    },
    [clientMenu, getFinalItems]
  );

  const [userMenuItems, setMenuItems] = useState(getClientMenuItems('basic'));

  const setClientMenuItems = useCallback(() => {
    if (subscription.subscriptionStatus === SUB_STATUS.HAS_SUBSCRIPTION) {
      if (userRole === UserRole.ADMIN) {
        setMenuItems(
          subscription.isFreemium ? getClientMenuItems('freemium') : getClientMenuItems('admin')
        );
      } else if (userRole === UserRole.USER) {
        setMenuItems(getClientMenuItems('user'));
      }
    } else if (
      subscription.subscriptionStatus === SUB_STATUS.SUBSCRIPTION_DISABLED &&
      !subscription.isActive
    ) {
      setMenuItems(getClientMenuItems('disabled'));
    } else if (userRole === UserRole.ENTERPRISE) {
      setMenuItems(getClientMenuItems('user'));
    } else {
      setMenuItems(getClientMenuItems('basic'));
    }
  }, [
    getClientMenuItems,
    subscription.isActive,
    subscription.isFreemium,
    subscription.subscriptionStatus,
    userRole,
  ]);

  const getAllowedMenuItems = () => {
    return [...userMenuItems.map((item) => item?.key)] as string[];
  };

  const freeTools: MenuItemProps = useMemo(
    () => ({
      key: 'tools',
      text: t('header.menu.free_tools'),
      children: [
        {
          key: 'color-checker',
          text: t('free_tools.color_checker.title'),
          href: buildLangPath('color-ratio-checker'),
        },
        {
          key: 'font-checker',
          text: t('free_tools.font_checker.title'),
          href: buildLangPath('font-checker'),
        },
      ],
    }),
    [t]
  );

  const getLPItems = (): MenuItemProps[] => [freeTools];

  const mainMenuItems = useMemo(
    (): MenuItemProps[] => [
      {
        key: 'platfrom',
        text: t('header.menu.the_platform'),
        href: buildLangPath('/'),
        children: [
          {
            key: 'faq',
            text: t('header.menu.faq'),
            href: buildLangPath('faq'),
          },
          {
            key: 'whitepapers',
            text: t('header.menu.whitepapers'),
            href: buildLangPath('whitepapers'),
          },
        ],
      },
      {
        key: 'company',
        text: t('header.menu.the_company'),
        children: [
          { key: 'about-us', text: t('header.menu.about_us'), href: buildLangPath('about-us') },
          { key: 'our-team', text: t('header.menu.our_team'), href: buildLangPath('our-team') },
        ],
      },
      {
        ...freeTools,
      },
      { key: 'pricing', text: t('header.menu.pricing'), href: buildLangPath('pricing') },
      {
        key: 'blog',
        text: t('header.menu.blog'),
        href: `https://${PAGES.SHARED.BLOG}.user1st.com/`,
      },
    ],
    [t, freeTools]
  );

  const footerMenuItems = useMemo(
    (): MenuItemProps[] => [
      mainMenuItems[0],
      mainMenuItems[1],
      {
        key: 'packages',
        text: t('header.menu.packages'),
        children: [
          { key: 'pricing', text: t('header.menu.pricing'), href: buildLangPath('pricing') },
        ],
      },
    ],
    [t, mainMenuItems]
  );

  const buildMenuItems = (menuItems: MenuItemProps[]) => {
    const items: MenuProps['items'] = [];
    menuItems.forEach((mi) => {
      let label;
      const children: MenuProps['items'] = [];
      if (mi.children) {
        label = (
          <>
            <PinkGradientLink text={mi.text} excludeEl={true} />
            <CaretDown />
          </>
        );
        mi.children.forEach((child) => {
          children?.push({
            key: child.key,
            label: <U1Link href={child.href} text={child.text} excludeEl={true} />,
            className: 'main-menu-item',
          });
        });
      } else {
        label = <PinkGradientRouterLink href={mi.href} text={mi.text} excludeEl={true} />;
      }
      const defaultProps = { key: mi.key, label };
      const finalProps = children ? { ...defaultProps, children } : defaultProps;
      items.push(finalProps);
    });
    return items;
  };

  const getLPMenuItems = (): MenuProps['items'] => {
    return buildMenuItems(getLPItems());
  };

  const getMenuItems = (): MenuProps['items'] => {
    return buildMenuItems(mainMenuItems);
  };

  const getAdminMenuItems = useCallback(() => {
    return getFinalItems(adminMenu);
  }, [adminMenu, getFinalItems]);

  const getVendorMenuItems = useCallback(() => {
    return getFinalItems(vendorMenu);
  }, [getFinalItems, vendorMenu]);

  useEffect(() => {
    if (userType === USER_TYPE.ADMIN) {
      setMenuItems(getAdminMenuItems());
    } else if (userType === USER_TYPE.VENDOR) {
      setMenuItems(getVendorMenuItems());
    } else {
      setClientMenuItems();
    }
  }, [subscription, userType, getAdminMenuItems, getVendorMenuItems, setClientMenuItems]);

  return {
    userMenuItems,
    mainMenuItems,
    footerMenuItems,
    getAllowedMenuItems,
    getMenuItems,
    getClientMenuItems,
    getAdminMenuItems,
    getVendorMenuItems,
    getLPMenuItems,
  };
};
